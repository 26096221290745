<template>
  <div>
    <PageHeader :items="items" />
    <div class="row mb-4">
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("First Name") }}</label>
          <input
            id="firstName"
            v-model="form.firstName"
            name="title"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Last Name") }}</label>
          <input
            id="lastName"
            v-model="form.lastName"
            name="title"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Email") }}</label>
          <input
            id="email"
            v-model="form.email"
            name="title"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("City") }}</label>
          <input
            id="title"
            v-model="form.city"
            name="title"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Street") }}</label>

          <input
            id="street"
            v-model="form.street"
            name="street"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Street Number") }}</label>
          <input
            id="streetNumber"
            v-model="form.streetNumber"
            name="streetNumber"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("ZIP") }}</label>
          <input
            id="zip"
            v-model="form.zip"
            name="zip"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Mobile") }}</label>
          <input
            id="mobile"
            v-model="form.mobile"
            name="mobile"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Phone") }}</label>
          <input
            id="phoneNo"
            v-model="form.phoneNo"
            name="phoneNo"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Roles") }}</label>
          <multiselect
            v-model="form.roles"
            label="title"
            :multiple="true"
            track-by="id"
            :options="roles"
            selectLabel=""
            deselectLabel=""
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Customer") }}</label>
          <MultiSelectInput
            v-model="form.company_id"
            :options="customers"
            label="companyName"
            trackBy="id"
            :key="form.company_id"
            moduleName="customers"
            :multiple="false"
          >
          </MultiSelectInput>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Types") }}</label>
          <multiselect
            v-model="form.types"
            :multiple="true"
            :options="types"
            selectLabel=""
            deselectLabel=""
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Password") }}</label>
          <input
            id="password"
            v-model="form.password"
            name="title"
            class="form-control"
            type="password"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="cars">{{ $t("Confirm Password") }}</label>

          <input
            id="confirmPassword"
            v-model="form.confirmPassword"
            name="title"
            class="form-control"
            type="password"
          />

          <div v-if="!passwordsMatch" style="color: red">
            {{ $t("Password and confirm password should be matched") }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="mt-4 max-w-3xl flex">
        <b-button @click="createUser()" class="mb-2 cursor-pointer">
          <span class="mr-25 align-middle">{{ $t("Create User") }}</span>
          <feather-icon icon="PlusIcon" size="12" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import MultiSelectInput from "@/components/MultiSelectInput.vue";

export default {
  components: {
    TextInput,
    PageHeader,
    Multiselect,
    MultiSelectInput,
  },
  data() {
    return {
      submitted: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        city: "",
        street: "",
        streetNumber: "",
        zip: "",
        mobile: "",
        phoneNo: "",
        roles: [],
        types: ["employee"],
        password: "",
        confirmPassword: "",
        company_id: "",
      },
      types: ["customer_employee", "employee", "partner"],
      passwordsMatch: true,
    };
  },
  computed: {
    ...mapGetters("roles", ["roles"]),
    ...mapGetters("customers", ["customers"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Users"),
          to: "/users",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
  },
  async created() {
    await this.$store.dispatch("roles/list", {
      limit_start: 0,
      limit_count: 100,
    });
    await this.$store.dispatch("customers/list");
  },

  methods: {
    async createUser() {
      this.passwordsMatch = true;
      this.submitted = true;
      if (this.form.password !== this.form.confirmPassword) {
        this.passwordsMatch = false;
      } else {
        this.passwordsMatch = true;
        const response = await this.$store.dispatch("users/create", {
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          mail: this.form.email,
          city: this.form.city,
          street: this.form.street,
          street_number: this.form.streetNumber,
          zip: this.form.zip,
          mobile: this.form.mobile,
          phone: this.form.phoneNo,
          company_id: this.form.company_id?.id ?? "",
          password: this.form.password,
          types: this.form.types,
          roles:
            this.form.roles && this.form.roles.length
              ? this.form.roles.map((role) => role.id)
              : [],
        });
        if (
          response.hasOwnProperty("message_type") &&
          response.message_type == "error"
        ) {
          // this.toast.fire({
          //   icon: "error",
          //   title: response?.message,
          // });
          // this.$store.commit("notificationMessages/notificationMessages", {
          //   icon: "error",
          //   title: response?.message,
          //   msg: "User Notification",
          // });
        } else {
          // const message = {
          //   icon: "success",
          //   title: this.$t("User") + " " + this.$t("created successfully"),
          //   msg: "User Notification",
          // };
          // this.toast.fire(message);
          // this.$store.commit("notificationMessages/notificationMessages", {
          //   icon: "success",
          //   title: "User created successfully",
          //   msg: "User Notification",
          // });
          await this.$router.push({ path: "/users" });
        }
      }
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
